import React from 'react';
import HomeTitle from '../../Components/HomeTitle/HomeTitle';
import './Home.css';

const Home = () => {
    
  
    return (
      <div id="snow-container"className="home-section">
        <HomeTitle />
      </div>
    );
  };
  
  export default Home;